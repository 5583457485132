<template>
  <zmAppPage
    class="selectBook"
    :showLeft="true"
    title="排行榜"
    :show-footer="true"
    :showBack="false"
    :showHeaderLeft="true"
    :show-header="true"
  >
    <div class="list-container cf">
      <div class="list-header">
        <van-row class="tc fs14">
          <van-col span="3">排名</van-col>
          <van-col span="3">姓名</van-col>
          <van-col span="12">所在公司</van-col>
          <van-col span="3">答对数</van-col>
          <van-col span="3">耗时</van-col>
        </van-row>
      </div>
      <zm-list :get-list="getList" ref="refList" :extra-params="query" @after-fetch="afterFetch">
        <template slot-scope="{ listData }">
          <div v-for="(item, index) in listData" :key="index" class="item" @click="onClick(item)">
            <van-row class="tc df-center">
              <van-col span="3" v-if="index === 0">
                <span class="rank rank-1"></span>
              </van-col>
              <van-col span="3" v-else-if="index === 1">
                <span class="rank rank-2"></span>
              </van-col>
              <van-col span="3" v-else-if="index === 2">
                <span class="rank rank-3"></span>
              </van-col>
              <van-col span="3" v-else>{{ index + 1 }}</van-col>
              <van-col span="3">{{ item.name }}</van-col>
              <van-col span="12" class="ellipsis">{{ item.company }}</van-col>
              <van-col span="3">{{ (item.score / 100).toFixed(0) }}</van-col>
              <van-col span="3">{{ item.duration | formatSeconds }}</van-col>
            </van-row>
          </div>
        </template>
      </zm-list>
    </div>
    <div slot="footer">
      <van-button type="danger" block>我的排名:{{ currentRank.rank }}</van-button>
    </div>
  </zmAppPage>
</template>

<script>
import { formatSeconds } from '@/pages/home/getSecond';
import * as auth from '@/utils/auth';
export default {
  name: 'Home',
  filters: {
    formatSeconds(input) {
      return formatSeconds(input);
    }
  },
  data() {
    return {
      list: [],
      query: {
        examId: auth.loc.get('examId', '')
      },
      currentRank: {}
    };
  },
  computed: {},
  watch: {
    // 'currentBook.id'(){
    // },
  },
  created() {
    this.getList();
  },
  methods: {
    afterFetch(res) {
      // this.currentRank = res.currentRank;
    },
    getList(params) {
      return this.$apis.getRank(params);
    }
  }
};
</script>
<style lang="scss" scoped>
.list-container {
  .list-header {
    padding: 10px;
    color: #fff;
    background: $--color-danger;
  }
}
.rank {
  display: inline-block;
  width: 0.33rem;
  vertical-align: middle;
  height: 0.44rem;
  background: url('~@/assets/img/rank-1.png') no-repeat center top;
  background-size: contain;
  &-2 {
    background-image: url('~@/assets/img/rank-2.png');
  }
  &-3 {
    background-image: url('~@/assets/img/rank-3.png');
  }
}

.item {
  background: #fff;
  // border-bottom:1px solid #dedede;
  margin-bottom: 2px;
  height: 0.64rem;
  padding: 0.1rem 0.05rem;
  font-size: 0.24rem;
}
</style>
